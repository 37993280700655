const Home = () => {
  return (
    <>
      <h1>Home</h1>
      <p>
        Vivamus et auctor diam, quis pulvinar tortor. Curabitur venenatis porttitor nulla eget molestie. Ut fermentum velit vel tempor porta. Curabitur ac mi magna. Quisque sagittis laoreet erat vitae cursus. Sed nec gravida mi. Donec sit amet sem eget est viverra faucibus. Integer ut neque blandit, ornare purus ut, dignissim risus. Quisque quis dui id dui varius viverra vitae vestibulum nunc.
      </p>
      <p>
        Sed ultrices nisi metus, in blandit quam bibendum vitae. Aliquam a risus ac tellus ultrices dapibus tincidunt quis mauris. Cras fringilla porttitor erat. Sed non vestibulum libero. Sed mattis nulla ligula. Phasellus feugiat diam non turpis mattis convallis. Praesent vestibulum, risus vel porttitor malesuada, magna massa finibus neque, sed vestibulum mi ligula ut tellus. Sed congue nunc quis ex molestie, efficitur interdum felis tincidunt. In eu ante malesuada, vehicula felis ac, rhoncus elit. Donec pulvinar justo at purus pharetra, sed blandit urna congue. Phasellus placerat rutrum arcu id porta. In nec vehicula est. Aenean euismod dignissim dolor quis tempor. Aliquam id risus nulla.
      </p>
    </>
  )
}

export default Home